/* In the Global Context */
export const SAVE_PREVPATH = 'SAVE_PREVPATH';
export const CLEAN_PREVPATH = 'CLEAN_PREVPATH';
export const TOTAL_STEP_PURCHASE_FLOW = 'TOTAL_STEP_PURCHASE_FLOW';
export const STATUS_PROFILE_MENU = 'STATUS_PROFILE_MENU';
export const NEXT_STEP_PURCHASE_FLOW = 'NEXT_STEP_PURCHASE_FLOW';
export const GO_BACK_STEP_PURCHASE_FLOW = 'GO_BACK_STEP_PURCHASE_FLOW';
export const UPDATE_CURRENT_VIEW_PURCHASE_FLOW = 'UPDATE_CURRENT_VIEW_PURCHASE_FLOW';
export const UPDATE_SUCCESS_PAYMENT = 'UPDATE_SUCCESS_PAYMENT';
export const UPDATE_PURCHASE_DATA_FORM = 'UPDATE_PURCHASE_DATA_FORM';
export const IS_LOGIN = 'IS_LOGIN';
export const IS_COUPON_100_PERCENT = 'IS_COUPON_100_PERCENT';
export const UPDATE_PURCHASE_DESCRIPTION = 'UPDATE_PURCHASE_DESCRIPTION';
export const UPDATE_GEOLOCALIZATION_DATA = 'UPDATE_GEOLOCALIZATION_DATA';
export const UPDATE_PURCHASE_COUPON = 'UPDATE_PURCHASE_COUPON';
export const UPDATE_PURCHASE_COUPON_DESCRIPTION = 'UPDATE_PURCHASE_COUPON_DESCRIPTION';
export const UPDATE_STATE_EVENTS = 'UPDATE_STATE_EVENTS';
export const UPDATE_PURCHASE_TYPE = 'UPDATE_PURCHASE_TYPE';
export const UPDATE_STATUS_CHILDREN = 'UPDATE_STATUS_CHILDREN';
export const SAVE_PREV_PATH = 'SAVE_PREV_PATH';
export const CLEAN_PURCHASE_DATA = 'CLEAN_PURCHASE_DATA';
export const UPDATE_LOG_NIUBIZ = 'UPDATE_LOG_NIUBIZ';
export const UPDATE_USERNAME_ONBOARDING = 'UPDATE_USERNAME_ONBOARDING';
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
export const UPDATE_CURRENT_STEP = 'UPDATE_CURRENT_STEP';
/* Update data payment - start */
export const UPDATE_EMPTY = 'UPDATE_EMPTY';
export const UPDATE_COMPLETE = 'UPDATE_COMPLETE';
export const UPDATE_ERRORS = 'UPDATE_ERRORS';
export const UPDATE_DLOCAL_INSTANCE = 'UPDATE_DLOCAL_INSTANCE';
export const UPDATE_FIELDS = 'UPDATE_FIELDS';
