import {
  CLEAN_PREVPATH,
  CLEAN_PURCHASE_DATA,
  GO_BACK_STEP_PURCHASE_FLOW,
  IS_COUPON_100_PERCENT,
  IS_LOGIN,
  NEXT_STEP_PURCHASE_FLOW,
  SAVE_PREVPATH,
  SAVE_PREV_PATH,
  TOTAL_STEP_PURCHASE_FLOW,
  UPDATE_CURRENT_STEP,
  UPDATE_CURRENT_VIEW_PURCHASE_FLOW,
  UPDATE_SUCCESS_PAYMENT,
  UPDATE_GEOLOCALIZATION_DATA,
  UPDATE_LOG_NIUBIZ,
  UPDATE_PURCHASE_COUPON,
  UPDATE_PURCHASE_COUPON_DESCRIPTION,
  UPDATE_PURCHASE_DATA_FORM,
  UPDATE_PURCHASE_DESCRIPTION,
  UPDATE_PURCHASE_TYPE,
  UPDATE_SETTINGS,
  UPDATE_STATE_EVENTS,
  UPDATE_STATUS_CHILDREN,
  UPDATE_USERNAME_ONBOARDING
} from '../types';

export default function GlobalReducer(state, action) {
  switch (action.type) {
    case SAVE_PREVPATH:
      return {
        ...state,
        prevPath: action.payload
      };
    case CLEAN_PREVPATH:
      return {
        ...state,
        prevPath: ''
      };
    case UPDATE_USERNAME_ONBOARDING:
      return {
        ...state,
        username_Onboarding: action.payload
      };
    case TOTAL_STEP_PURCHASE_FLOW:
      return {
        ...state,
        purchaseFlow_TotalStep: action.payload
      };
    case NEXT_STEP_PURCHASE_FLOW:
      return {
        ...state,
        purchaseFlow_CurrentStep: state.purchaseFlow_CurrentStep + 1
      };
    case GO_BACK_STEP_PURCHASE_FLOW:
      return {
        ...state,
        purchaseFlow_CurrentStep: state.purchaseFlow_CurrentStep - 1
      };
    case UPDATE_CURRENT_STEP:
      return {
        ...state,
        purchaseFlow_CurrentStep: action.payload
      };
    case UPDATE_CURRENT_VIEW_PURCHASE_FLOW:
      return {
        ...state,
        purchaseFlow_CurrentView: action.payload
      };
    case UPDATE_SUCCESS_PAYMENT:
      return {
        ...state,
        isSuccessPayment: action.payload
      };
    case UPDATE_PURCHASE_TYPE:
      return {
        ...state,
        purchaseFlow_Type: action.payload
      };
    case UPDATE_STATUS_CHILDREN:
      return {
        ...state,
        purchaseStatusChildren: action.payload
      };
    case SAVE_PREV_PATH:
      return {
        ...state,
        purchaseFlow_PrevPath: action.payload
      };
    case UPDATE_PURCHASE_DATA_FORM:
      return {
        ...state,
        purchaseFlow_Data_Form: {
          ...state.purchaseFlow_Data_Form,
          ...action.payload
        }
      };
    case IS_LOGIN:
      return {
        ...state,
        isLogin: action.payload
      };
    case IS_COUPON_100_PERCENT:
      return {
        ...state,
        isCoupon100percent: action.payload
      };
    case UPDATE_PURCHASE_DESCRIPTION:
      return {
        ...state,
        purchase_Description: {
          ...state.purchase_Description,
          ...action.payload
        }
      };
    case UPDATE_GEOLOCALIZATION_DATA:
      return {
        ...state,
        geolocalization_data: {
          ...state.geolocalization_data,
          ...action.payload
        }
      };
    case UPDATE_PURCHASE_COUPON:
      return {
        ...state,
        purchase_Coupon: action.payload
      };
    case UPDATE_PURCHASE_COUPON_DESCRIPTION:
      return {
        ...state,
        purchase_Coupon_Description: {
          ...state.purchase_Coupon_Description,
          ...action.payload
        }
      };
    case UPDATE_STATE_EVENTS:
      return {
        ...state,
        state_events: {
          ...state.state_events,
          ...action.payload
        }
      };
    case UPDATE_LOG_NIUBIZ:
      return {
        ...state,
        log_niubiz: {
          ...state.log_niubiz,
          ...action.payload
        }
      };
    case UPDATE_SETTINGS: {
      return {
        ...state,
        settings: action.payload
      };
    }
    case CLEAN_PURCHASE_DATA:
      return {
        ...state,
        purchaseFlow_TotalStep: 4,
        purchaseFlow_CurrentStep: 1,
        purchaseFlow_CurrentView: 'register',
        isCoupon100percent: false,
        purchaseFlow_Data_Form: {
          id_hubspot: '',
          guardian_first_name: '',
          guardian_last_name: '',
          guardian_email: '',
          guardian_phone_number: '',
          price_purchase: '',
          user_id: '',
          enrolment_id: '',
          cupon: '',
          discount_off: ''
        },
        purchase_Description: {
          name_product: '',
          age_range: '',
          schedule: [],
          duration: '',
          days_start: null,
          isArrayGroup: false,
          firstCourse: [],
          secondCourse: [],
          array_group: [],
          start_date: '',
          number_course: '',
          price: '',
          before_price: '',
          price_per_month: '',
          currencyCode: '',
          ico: '',
          isFullPath: null,
          isSubscription: null,
          id_group: null,
          courses_in_path: [],
          type_purchase: ''
        },
        geolocalization_data: {
          loading: true,
          country: null,
          countryCode: null,
          city: null,
          continent: null,
          region: null,
          ipAddress: null,
          currency: null,
          currencySymbol: null,
          timezone: null,
          status: null
        },
        state_events: {
          state_profile_from_menu: false,
          previous_url: null,
          previous_url_hour: null,
          current_url: null,
          current_url_hour: null
        },
        isSuccessPayment: false,
        log_niubiz: {
          error_code: '',
          transaction_id: '',
          description: ''
        },
        purchaseFlow_Type: 'purchase-flow',
        purchaseStatusChildren: true,
        purchaseFlow_PrevPath: '',
        purchase_Coupon: false,
        purchase_Coupon_Description: {
          couponProgram: '',
          couponCode: '',
          couponType: '',
          couponItemAmount: '',
          couponDiscount: '',
          couponApplyAmount: '',
          cumulative: '',
          is_redeemable_only_once: false,
          was_redeemed: false
        },
        checkout_CurrentView: 'login'
      };
    default:
      return state;
  }
}
