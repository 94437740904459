import withFBQ from 'next-fbq';
import Router from 'next/router';
import React, { useEffect } from 'react';
import { func, shape } from 'prop-types';
import TagManager from 'react-gtm-module';

import { GTM_TRACKING_ID } from '#helpers/gtag';

import '../styles/globals.css';
import * as gtag from '../helpers/gtag';
import GlobalState from '../context/Global/GlobalState';

// Notice how we track pageview when route is changed
Router.events.on('routeChangeComplete', url => gtag.pageview(url));

const facebookId = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID;
const ENVIRONMENT = process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT;

function MyApp({ Component, pageProps }) {
  useEffect(() => {
    if (ENVIRONMENT === 'production') {
      TagManager.initialize({ gtmId: GTM_TRACKING_ID });
    }
  }, []);

  return (
    <GlobalState>
      <Component {...pageProps} />
    </GlobalState>
  );
}

MyApp.propTypes = {
  Component: func,
  pageProps: shape()
};

const APP = ENVIRONMENT === 'production' ? withFBQ(facebookId, Router)(MyApp) : MyApp;

export default APP;
