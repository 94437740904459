export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID;
export const GO_TRACKING_ID = process.env.NEXT_PUBLIC_GO_ID;
export const GTM_TRACKING_ID = process.env.NEXT_PUBLIC_GTM_ID;
export const STONLY_WID = process.env.NEXT_PUBLIC_STONLY_WID;

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages

export const pageview = url => {
  const userData = JSON.parse(localStorage.getItem('userData'));

  if (
    window !== 'undefined' &&
    window.gtag !== 'undefined' &&
    typeof window.gtag === 'function'
  ) {
    window.gtag('config', GA_TRACKING_ID, { page_path: url });
    window.gtag('set', GA_TRACKING_ID, { user_id: userData ? userData.id : '' });
  }
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = (action, category, label, user_id) => {
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    user_id: user_id
  });
};

export const eventTimer = (name, category) => {
  if (window.performance) {
    var timeSincePageLoad = Math.round(performance.now());
    window.gtag('event', 'timing_complete', {
      name: name,
      value: timeSincePageLoad,
      event_category: category
    });
  }
};
