import { useReducer } from 'react';

import GlobalContext from './GlobalContext';
import GlobalReducer from './GlobalReducer';
import {
  SAVE_PREVPATH,
  CLEAN_PREVPATH,
  TOTAL_STEP_PURCHASE_FLOW,
  NEXT_STEP_PURCHASE_FLOW,
  GO_BACK_STEP_PURCHASE_FLOW,
  UPDATE_CURRENT_VIEW_PURCHASE_FLOW,
  UPDATE_SUCCESS_PAYMENT,
  UPDATE_PURCHASE_DATA_FORM,
  IS_LOGIN,
  IS_COUPON_100_PERCENT,
  UPDATE_PURCHASE_DESCRIPTION,
  UPDATE_GEOLOCALIZATION_DATA,
  UPDATE_PURCHASE_COUPON,
  UPDATE_PURCHASE_COUPON_DESCRIPTION,
  UPDATE_STATE_EVENTS,
  CLEAN_PURCHASE_DATA,
  UPDATE_LOG_NIUBIZ,
  UPDATE_USERNAME_ONBOARDING,
  UPDATE_SETTINGS,
  UPDATE_PURCHASE_TYPE,
  UPDATE_STATUS_CHILDREN,
  SAVE_PREV_PATH,
  UPDATE_CURRENT_STEP,
  UPDATE_EMPTY,
  UPDATE_COMPLETE,
  UPDATE_ERRORS,
  UPDATE_DLOCAL_INSTANCE,
  UPDATE_FIELDS
} from '../types';

const GlobalState = props => {
  const initialState = {
    prevPath: '',
    username_Onboarding: '',
    purchaseFlow_TotalStep: 4,
    purchaseFlow_CurrentStep: 1,
    purchaseFlow_CurrentView: 'register',
    purchaseFlow_Type: 'purchase-flow',
    purchaseStatusChildren: true,
    purchaseFlow_Data_Form: {
      id_hubspot: '',
      guardian_first_name: '',
      guardian_last_name: '',
      student_first_name: '',
      student_last_name: '',
      guardian_email: '',
      guardian_phone_number: '',
      guardian_country: '',
      guardian_city: '',
      price_purchase: '',
      user_id: '',
      enrolment_id: '',
      invoice_id: '',
      cupon: '',
      discount_off: ''
    },
    purchase_Description: {
      name_product: '',
      id_pathlearning: '',
      name_pathlearning: '',
      age_range: '',
      schedule: [],
      duration: '',
      start_date: '',
      number_course: '',
      price: '',
      before_price: '',
      price_per_month: '',
      currencyCode: '',
      ico: '',
      isFullPath: null,
      isSubscription: null,
      id_group: null,
      is_variations: null,
      id_variations: null,
      is_kit: null,
      courses_in_path: [],
      type_purchase: '',
      typeSubscription: null,
      exist_discount: false
    },
    geolocalization_data: {
      loading: true,
      country: null,
      countryCode: null,
      city: null,
      continent: null,
      region: null,
      ipAddress: null,
      currency: null,
      currencySymbol: null,
      timezone: null,
      status: null
    },
    state_events: {
      state_profile_from_menu: false,
      previous_url: null,
      previous_url_hour: null,
      current_url: null,
      current_url_hour: null
    },
    log_niubiz: {
      error_code: '',
      transaction_id: '',
      description: '',
      card: '',
      brand: '',
      date: ''
    },
    isLogin: false,
    isCoupon100percent: false,
    isSuccessPayment: false,
    settings: {
      announcement_bar_active: false,
      announcement_bar_text: null,
      announcement_bar_url: null,
      free_courses_discount_active: false,
      free_courses_discount_value: null,
      courses_with_learning_path_discount_active: false,
      courses_with_learning_path_discount_value: null
    },
    purchaseFlow_PrevPath: '1',
    purchase_Coupon: false,
    purchase_Coupon_Description: {},
    checkout_CurrentView: 'login'
  };

  const [state, dispatch] = useReducer(GlobalReducer, initialState);

  /*  Function or actions */

  //Update setrings
  const updateSettings = settings => {
    dispatch({ type: UPDATE_SETTINGS, payload: settings });
  };

  //Save the prevPath
  const getPrevPath = path => {
    dispatch({ type: SAVE_PREVPATH, payload: path });
  };

  //Clean the prevPath
  const cleanPrevPath = () => {
    dispatch({ type: CLEAN_PREVPATH });
  };

  const udpateUsernameOnboarding = username => {
    dispatch({ type: UPDATE_USERNAME_ONBOARDING, payload: username });
  };

  //* Purchase Flow
  const updateTotalStep = steps => {
    dispatch({ type: TOTAL_STEP_PURCHASE_FLOW, payload: steps });
  };

  const updatePurchaseFlowType = type => {
    dispatch({ type: UPDATE_PURCHASE_TYPE, payload: type });
  };

  const updateStatusChildren = type => {
    dispatch({ type: UPDATE_STATUS_CHILDREN, payload: type });
  };

  const savePrevPath = type => {
    dispatch({ type: SAVE_PREV_PATH, payload: type });
  };

  const goForward = () => {
    dispatch({ type: NEXT_STEP_PURCHASE_FLOW });
  };

  const goBack = () => {
    dispatch({ type: GO_BACK_STEP_PURCHASE_FLOW });
  };

  const updateCurrentPosition = currentPosition => {
    dispatch({ type: UPDATE_CURRENT_VIEW_PURCHASE_FLOW, payload: currentPosition });
  };

  const updateSuccessPayment = isSuccess => {
    dispatch({ type: UPDATE_SUCCESS_PAYMENT, payload: isSuccess });
  };
  /* purchaseFlow_Data_Form */
  const updateDataForm = dataForm => {
    dispatch({ type: UPDATE_PURCHASE_DATA_FORM, payload: dataForm });
  };

  const updateCurrentStep = step => {
    dispatch({ type: UPDATE_CURRENT_STEP, payload: step });
  };

  const updateCoupon100percent = status => {
    dispatch({ type: IS_COUPON_100_PERCENT, payload: status });
  };

  const isLoginUser = status => {
    dispatch({ type: IS_LOGIN, payload: status });
  };

  //update the purchase description card --> purchase_Description
  const updatePurchaseDescription = purchaseData => {
    dispatch({ type: UPDATE_PURCHASE_DESCRIPTION, payload: purchaseData });
  };

  //update geolocalization data --> geolocalization_data
  const updateGeolocalizationData = geolocalizationData => {
    dispatch({ type: UPDATE_GEOLOCALIZATION_DATA, payload: geolocalizationData });
  };
  //update state events Analytics
  const updateStateEvents = stateEvents => {
    dispatch({ type: UPDATE_STATE_EVENTS, payload: stateEvents });
  };

  const updatePurchaseCoupon = purchaseData => {
    dispatch({ type: UPDATE_PURCHASE_COUPON, payload: purchaseData });
  };

  const updatePurchaseCouponDescription = purchaseData => {
    dispatch({ type: UPDATE_PURCHASE_COUPON_DESCRIPTION, payload: purchaseData });
  };

  const cleanPurchaseData = () => {
    dispatch({ type: CLEAN_PURCHASE_DATA });
  };

  const updateLogNiubiz = log => {
    dispatch({ type: UPDATE_LOG_NIUBIZ, payload: log });
  };

  /* Update data payment - start */
  const setEmpty = emptyData => {
    dispatch({ type: UPDATE_EMPTY, payload: emptyData });
  };

  const setComplete = completeData => {
    dispatch({ type: UPDATE_COMPLETE, payload: completeData });
  };

  const setErrors = errorsData => {
    dispatch({ type: UPDATE_ERRORS, payload: errorsData });
  };

  const setDlocalInstance = dlocalInstance => {
    dispatch({ type: UPDATE_DLOCAL_INSTANCE, payload: dlocalInstance });
  };

  const setFields = fieldsData => {
    dispatch({ type: UPDATE_FIELDS, payload: fieldsData });
  };

  /* Update data payment - end */
  return (
    <GlobalContext.Provider
      value={{
        prevPath: state.prevPath,
        username_Onboarding: state.username_Onboarding,
        purchaseFlow_TotalStep: state.purchaseFlow_TotalStep,
        purchaseFlow_CurrentStep: state.purchaseFlow_CurrentStep,
        purchaseFlow_CurrentView: state.purchaseFlow_CurrentView,
        checkout_CurrentView: state.checkout_CurrentView,
        purchaseFlow_Data_Form: state.purchaseFlow_Data_Form,
        purchaseFlow_Type: state.purchaseFlow_Type,
        purchaseStatusChildren: state.purchaseStatusChildren,
        purchaseFlow_PrevPath: state.purchaseFlow_PrevPath,
        isLogin: state.isLogin,
        isCoupon100percent: state.isCoupon100percent,
        purchase_Description: state.purchase_Description,
        geolocalization_data: state.geolocalization_data,
        purchase_Coupon: state.purchase_Coupon,
        purchase_Coupon_Description: state.purchase_Coupon_Description,
        state_events: state.state_events,
        log_niubiz: state.log_niubiz,
        settings: state.settings,
        isSuccessPayment: state.isSuccessPayment,
        getPrevPath,
        cleanPrevPath,
        udpateUsernameOnboarding,
        updateTotalStep,
        goForward,
        goBack,
        updateCurrentPosition,
        updateSuccessPayment,
        updateDataForm,
        isLoginUser,
        updateCoupon100percent,
        updatePurchaseDescription,
        updateGeolocalizationData,
        updatePurchaseCoupon,
        updatePurchaseCouponDescription,
        updateStateEvents,
        cleanPurchaseData,
        updateLogNiubiz,
        updateSettings,
        updatePurchaseFlowType,
        updateStatusChildren,
        savePrevPath,
        updateCurrentStep,
        setEmpty,
        setComplete,
        setErrors,
        setDlocalInstance,
        setFields
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalState;
